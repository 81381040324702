<template>
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <br /><b class="font--xlarge" style="color: #49c5fe">FAQ</b>
        <br /><br /><b>1. ¿Cómo vende Nexgen sus licencias de usuario? </b>
        <br /><br />
        Nexgen utiliza un modelo de suscripción, por estudiante por año, para el
        curso neo. También ofrecemos precios por volumen; cuantas más licencias
        se adquieran, menor será el precio. Comuníquese con su representante de
        ventas para obtener más información.

        <br /><br /><b>2. ¿Qué es el CEFR? </b><br /><br />
        El CEFR (Marco Común Europeo de Referencia para las Lenguas) es un
        sistema estandarizado y aceptado internacionalmente utilizado para
        evaluar y describir los niveles de competencia lingüística. Categoriza
        las habilidades en seis niveles, desde A1 (principiante) hasta C2
        (proficiente), proporcionando una referencia común para estudiantes,
        instituciones y empleadores. Ayuda a los estudiantes a establecer metas,
        elegir cursos y comunicar la competencia de manera efectiva.

        <br /><br /><b>3. ¿Qué incluye el precio de la licencia de usuario? </b
        ><br /><br />
        La licencia del material didáctico de neo incluye acceso completo a lo
        siguiente durante todo el año escolar:

        <br /><br /><b>Para el estudiante: </b>

        <br /><br /><b>Prueba de Colocación: </b> Evalúe con precisión los
        niveles de competencia de los estudiantes con la prueba de nivelación
        alineada con el CEFR de neo, asegurando una colocación adecuada en el
        curso dentro de neoStudy.

        <br /><br /><b>Cursos neoStudy: </b> Acceda al material didáctico
        alineado con el CEFR y adecuado para la edad; en línea o a través de la
        aplicación neoStudy, proporcionando un sistema de aprendizaje integral y
        atractivo.

        <br /><br /><b>Pruebas de Dominio: </b> valúe continuamente el progreso
        del estudiante con pruebas automatizadas, permitiendo que la
        inteligencia artificial de neo personalice los caminos de estudio de los
        estudiantes para obtener los mejores resultados.

        <br /><br /><b>Pruebas de Certificación: </b> Valide la adquisición de
        habilidades del CEFR y distribuya certificados de inglés alineados con
        el CEFR con marcas compartidas para sus estudiantes.

        <br /><br /><b>Certificados de Inglés: </b> hasta 11 Certificados de
        Inglés que pueden incluir la marca de su institución. Comprueban la
        fluidez de sus estudiantes y distinguen a su institución de las demás.

        <br /><br /><b>Velocidad de fluidez inigualable: </b> Obtenga el
        Certificado de cada nivel en solo 36 horas de estudio independiente,
        flexible para adaptarse a programas académicos actuales.

        <br /><br /><b>Estudie en cualquier lugar, en cualquier momento: </b>
        Estudie perfectamente de varios dispositivos, el estudio se sincroniza
        en la nube de neo.

        <br /><br /><b>Recursos para el profesor: </b>

        <br /><br /><b>Curso en línea de Formación para Profesores: </b> Prepare
        a los profesores para el éxito con el curso de certificación integral y
        disponible desde la plataforma de neo.

        <br /><br /><b>Capacitación y Soporte Localizado: </b> Reciba
        capacitación oportuna y soporte continuo de socios capacitados y
        autorizados por neo.

        <br /><br /><b>Actividades de Clase Recomendadas: </b> Mejore la
        enseñanza con planes de lección en tiempo real, actividades
        seleccionadas por inteligencia artificial y cuadernos de trabajo en PDF.

        <br /><br /><b>Calificación e Informes: </b> Simplifique la calificación
        de los estudiantes con nuestro sistema inmediato y fácil de usar,
        adaptado a los objetivos de la escuela.

        <br /><br /><b>neoDashboard: </b> Administre y supervise centralmente el
        progreso del estudiante, el rendimiento en las pruebas, las
        calificaciones y las descargas de actividades.

        <br /><br /><b>Recursos adicionales del programa: </b>

        <br /><br /><b>Servidores basados en la nube: </b> Los servidores
        seguros y respaldados por neo cumplen con los requisitos de protección
        de datos europeos y estadounidenses.

        <br /><br /><b>Gestión de Certificados: </b> Distribuya fácilmente
        Certificados alineados con el CEFR, con opciones de descarga para los
        estudiantes o distribución en toda la escuela.

        <br /><br /><b>Co-branding: </b> Personalice su nuevo y excepcional
        programa de inglés agregando el emblema o logotipo de su institución a
        las pruebas de nivelación y certificados, dando a su escuela una ventaja
        competitiva.

        <br /><br /><b>4. ¿Qué es neoJr? </b><br /><br />
        neoJr+ es el curso de neo para estudiantes de educación primaria
        (Primaria I) de 4 a 11 años.

        <br /><br /><b>5. ¿Qué es NeoPrep+?</b> <br /><br />
        neoPrep+ es el curso de neo para estudiantes de educación secundaria
        (Primaria II) y educación secundaria (Educación Secundaria).

        <br /><br /><b>6. ¿Qué es neo? </b><br /><br />
        neo+ es el curso de neo para el aprendizaje de nivel superior, ya sea a
        nivel universitario o para profesionales adultos.

        <br /><br /><b
          >7. ¿En qué dispositivos pueden los estudiantes usar el curso neo?
        </b>
        <br /><br /><b>neoStudy en línea: </b><br />
        Google Chrome versión 93 o posterior (Windows/MacOS/Chromebook)

        <br /><br /><b>Aplicación neoStudy en dispositivos móviles: </b><br />
        iOS 10.0 o posterior, Android 4.1 Jelly Bean o posterior y macOS 11.0 o
        posterior con procesador Apple M1.

        <br /><br /><b
          >8. ¿Puede neo trabajar con mis libros de texto de inglés? </b
        ><br /><br />
        Sí, por supuesto. neo puede ser fácilmente la base del programa de
        inglés de una escuela o un complemento para un programa existente. De
        cualquier manera, los estudiantes aprenden a un ritmo acelerado y son
        recompensados con certificados de inglés alineados con el CEFR que
        pueden asociarse con el logotipo de su organización.

        <br /><br /><b
          >9. ¿Qué función tendrán mis profesores en su programa? </b
        ><br /><br />
        En Nexgen, reconocemos y valoramos el papel crucial que desempeñan los
        profesores en el proceso de aprendizaje. En nuestro programa, sus
        profesores son parte integral de la ecuación. Nuestra solución de inglés
        trabaja en conjunto con sus conocimientos, creando una poderosa
        combinación de aprendizaje. Estamos comprometidos en apoyar a sus
        profesores, reduciendo la carga de trabajo, mejorando los resultados de
        los estudiantes y promoviendo la satisfacción en el trabajo. La
        formación integral de profesores y el apoyo continuo son pilares
        fundamentales de nuestro éxito.
      </p>
    </div>
  </section>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>